<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="tooltip" :label="__('Tooltip')">
            <el-input v-model="nodeToBind.tooltip" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import _ from "lodash";

export default {
  mixins: [BaseNode],
  components: {},
  data() {
    return {
      rules: {}
    };
  },
  computed: {},
  methods: {
    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    this.$set(this.nodeToBind, "node_type", NODE_TYPES.HANGUP.NODE_TYPE);
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
